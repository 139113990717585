#ib_report {
  .query {
    width: 100%;
    margin-bottom: 60px;

    .query_content_main {
      width: 100%;
      margin: 0 auto;
      margin-bottom: 40px;
      display: grid;
      grid-template-columns: calc(85% - 16px) calc(15% - 16px);
      column-gap: 32px;

      .query_content_main_left {
        .el-form {
          width: 400px;
        }

        .calendar {
          width: 100%;
          margin-left: 0;
          margin-right: 0;
        }
      }

      .query_content_main_right {
        position: relative;

        .el-button {
          position: absolute;
          top: 20%;

          &:not(:first-child) {
            margin-left: 0;
            top: 60%;
          }
        }
      }
    }

    .calendar_box {
      width: 100%;
      margin: 0 auto;

      ul {
        width: calc(350px * 2 + 25px);
        display: grid;
        grid-template-columns: 50% 50%;
        column-gap: 32px;
        row-gap: 25px;
        margin: 0 auto;

        li {
          background-color: $secondary-green;
          border-radius: $container-radius;
          padding: 16px;
          display: grid;
          grid-template-columns: calc(50% - 10px) calc(50% - 10px);
          column-gap: 20px;

          .left {
            background-color: $secondary-green;
            height: 109px;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              width: 80px;
              vertical-align: middle;
            }
          }

          .right {
            display: flex;
            align-items: center;

            > div {
              p {
                font-size: 32px;
                font-weight: bold;
                line-height: 1.38;
                color: $white;
              }

              span {
                font-size: 16px;
                font-weight: 200;
                line-height: 1.56;
                color: $white;
              }

              img {
                @include rtl-sass-value(float, right, left);
                width: 16px;
              }
            }
          }
        }
      }
    }
  }

  .content {
    width: 100%;
    overflow: hidden;

    .content_box {
      width: 100%;
      @include rtl-sass-value(float, left, right);
      height: 100%;

      .select {
        display: flex;
        gap: 32px;
        justify-content: space-between;
        .time-type{
          max-width: 200px;
        }
        @media screen and (max-width: 768px) {
          flex-direction: column;
          .time-type{
            max-width: initial;
          }
        }
        p {
          font-size: 32px;
          font-weight: bold;
          line-height: 1.38;
          color: $primary-purple;
        }
      }

      .openedAccounts {
        /deep/ .el-table th .cell {
          min-height: unset;
          display: block;
          padding: 15px 10px;
        }
      }
    }

    .table_box {
      margin: 30px 0px;
    }
  }
}

@media (max-width: 991px) {
  #ib_report .query .query_content_main {
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
    text-align: center;

    .query_content_main_right {
      .el-button {
        top: 20% !important;
        margin-top: 30px;
        position: relative;
      }
    }
  }
}

@media (max-width: 768px) {
  #ib_report .query {
    .query_content_main .query_content_main_right .el-button {
      margin-top: 40px;
    }
    .calendar_box ul {
      width: 100%;
      grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
    }
  }
}

@media (max-width: 550px) {
  #ib_report {
    .query {
      margin-bottom: 24px;

      .query_content_main {
        margin-bottom: 40px;

        .query_content_main_left .el-form {
          width: 100%;
        }

        /deep/ .el-form-item {
          margin-bottom: 12px;
        }
      }

      .calendar_box ul li .left > div p {
        font-size: 24px;
      }
    }

    .content .content_box .select p {
      font-size: 24px;
      text-align: center;
    }
  }
}
