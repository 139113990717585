.calendar {
  margin-left: 2%;
  margin-right: 2%;
  @include rtl-sass-value(float, left, right);
  width: 100%;

  .calendar_shortcut {
    width: 30%;
    height: 35px;
    @include rtl-sass-value(float, left, right);
    @include rtl-sass-prop(margin-right, margin-left, calc(100% - 97%));
  }

  .calendar_start_date {
    width: 30%;
    height: 100%;
    @include rtl-sass-value(float, left, right);
  }

  .calendar_to {
    width: 7%;
    height: 100%;
    @include rtl-sass-value(float, left, right);
    text-align: center;
    font-size: 20px;
    font-weight: 200;
    line-height: 1.5;
    color: $primary-purple;
    margin-top: 11px;
  }

  .calendar_end_date {
    width: 30%;
    height: 100%;
    @include rtl-sass-value(float, left, right);
  }

  .calendar_start_date,
  .calendar_end_date {
    cursor: pointer;
    /deep/ .el-input {
      width: 100%;
      .el-input__inner {
        cursor: pointer;
      }
    }
  }
}

@media (max-width: 768px) {
  .calendar {
    height: 35px;
    @include rtl-sass-value(margin-right, 2%, 0);
    @include rtl-sass-value(margin-left, 0, 2%);
    @include rtl-sass-value(float, left, right);

    .calendar_shortcut {
      width: 30%;
      height: 35px;
      @include rtl-sass-value(float, left, right);
      @include rtl-sass-prop(margin-right, margin-left, calc(100% - 95%));
    }

    .calendar_start_date {
      width: 30%;
      height: 100%;
      @include rtl-sass-value(float, left, right);
    }

    .calendar_to {
      width: 5%;
      height: 100%;
      @include rtl-sass-value(float, left, right);
      font-size: 13px;
      text-align: center;
      line-height: 35px;
    }

    .calendar_end_date {
      width: 30%;
      height: 100%;
      @include rtl-sass-value(float, left, right);
    }
  }
}

@media (max-width: 550px) {
  .calendar {
    min-height: 80px;
    .calendar_shortcut {
      width: 100%;
      margin-bottom: 20px;
    }
    .calendar_start_date,
    .calendar_end_date {
      width: 45%;
    }
    .calendar_to {
      width: 10%;
      height: 35px;
    }
  }
}
